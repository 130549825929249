import styled from 'styled-components';

import { ParagraphM, SubheadingS, SubheadingXS, SubheadingXXS } from '../../Text';
import Hashtag from './Hashtag';

const StyledButtonChannels = styled.div`
  background-color: #EBEEEF;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const WrapperDoWhat = styled.div`
  display: flex;
  background-color: var(--b-b100);
  margin: 1rem 0rem 1rem 0rem;
  padding: 1rem;

  .info-circle {
    align-self: center;
    display: flex;
    margin-left: 6px;
  }
`;

const Description = styled.div`
  
  .innerHtml{
    font-family: "Avenir LT Std";
    padding-top: 1rem;

    a:link{
      color: var(--p500);
    }
`;

const Tags = styled.div`
  .sub_heading_xxs {
    background-color: var(--b-b100);
    padding: .5rem .7rem .5rem .7rem;
    width: auto;
    border-radius: 75px;
    display: inline-block;
    color: var(--b-b500);
  }
  margin: 10px 0px;

`;

const HashtagSlider = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 16px 0;

`;
const Instagram = ({data}) => {
  const hasInstagramDoWhat = data?.channels?.instagram?.instagram_do_what;
  const hasInstagramTagAccount = data?.channels?.instagram?.instagram_tag_account;
  const hasInstagramHashtags = data?.channels?.instagram?.instagram_tag_hashtag;
  const hasInstagramInstructions = data?.channels?.instagram?.instagram_instructions;

  return (
    <StyledButtonChannels>
      <Description>
      <SubheadingS mt={"0px"}>I would like the creative to:</SubheadingS>
      {hasInstagramDoWhat ? (
        hasInstagramDoWhat.map((instagram_do_what) => (
          <WrapperDoWhat>
            <SubheadingXS key={instagram_do_what}>
              {instagram_do_what}
            </SubheadingXS>
          </WrapperDoWhat>
        ))
      ) : (
        <SubheadingXXS>No specific instructions given</SubheadingXXS>
      )}
      </Description>
      <Tags>
        <Description>
        <SubheadingS mt={"0px"}>And tag these instagram accounts:</SubheadingS>
      
        {hasInstagramTagAccount ? (
          <>
            {data?.channels?.instagram?.instagram_tag_account && (
              <>
                <HashtagSlider >
                <>
                  {data.channels.instagram.instagram_tag_account.map((tag) => (
                    <Hashtag name={tag} />
                  ))}
                  </>
                </HashtagSlider>
              </>
            )}
          </>
        ) : (
          <SubheadingXXS>No accounts to tag</SubheadingXXS>
        )}
        </Description>
      </Tags>
      <Tags>
        <Description>
        <SubheadingS mt={"0px"}>And use these hashtags</SubheadingS>
        {hasInstagramHashtags ? (
          <>
            {data?.channels?.instagram?.instagram_tag_hashtag && (
              <>
                <HashtagSlider >
                  {data.channels.instagram.instagram_tag_hashtag.map((tag) => (
                    <Hashtag name={tag} />
                  ))}
                </HashtagSlider>
              </>
            )}
          </>
        ) : (
          <SubheadingXXS>No hashtags given</SubheadingXXS>
        )}
        </Description>
      </Tags>
      <Description>
        <SubheadingS mt={"0px"}>And these are my instructions</SubheadingS>
        {hasInstagramInstructions ? (
          <Description>
            <div className="innerHtml" dangerouslySetInnerHTML={{ __html: hasInstagramInstructions }}></div>
          </Description>
        )
          : (
            <SubheadingXXS>No instructions</SubheadingXXS>
          )}
      </Description>
    </StyledButtonChannels>
  )
}

export default Instagram
