import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import CustomCheckbox from "../../Components/Common/FormInputs/Checkbox";
import { ParagraphM, SubheadingS } from "../../Components/Text";
import Accordion from "./Accordion";
import { MultiInput } from "../../Components/Common/FormInputs/MultiInput";
import RichTextComponent from "../../Components/Advertisers/Marketplace/RichTextComponent";
import styled from "styled-components";
import { isRichTextNotEmpty } from "../../Utils/utility-functions";

const FlexColContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChannelRequirements = ({ accordionStates, toggleAccordion }) => {
  const {
    control,
    setValue,
    getValues,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext();

  const instagramValues = watch("channels.instagram");
  const facebookValues = watch("channels.facebook");
  const pinterestValues = watch("channels.pinterest");
  const thursdValues = watch("channels.thursd");
  const otherValues = watch("channels.other");

  const handleCheckboxChange = (name, checked, key, value) => {
    const currentValues = getValues(name) || {};
    const updatedValues = { ...currentValues };

    if (!updatedValues[key]) {
      updatedValues[key] = [];
    }

    if (checked) {
      if (!updatedValues[key].includes(value)) {
        updatedValues[key].push(value);
      }
      clearErrors();
    } else {
      updatedValues[key] = updatedValues[key].filter((v) => v !== value);

      if (updatedValues[key].length === 0) {
        delete updatedValues[key];
      }
    }

    setValue(name, updatedValues);
  };

  const handleSingleCheckboxChange = (name, checked, key, value) => {
    const currentValues = getValues(name) || {};
    const updatedValues = { ...currentValues };

    if (checked) {
      updatedValues[key] = [value];
      clearErrors();
    } else {
      delete updatedValues[key];
    }
    setValue(name, updatedValues);
  };

  const handleMultiInputChange = (name, key, values) => {
    const currentValues = getValues(name) || {};
    const updatedValues = { ...currentValues, [key]: values };
    setValue(name, updatedValues);

    if (values.length > 0) {
      clearErrors();
    }
  };

  const handleRichTextChange = (field) => (content) => {
    field.onChange(content);
    if (isRichTextNotEmpty(content)) {
      clearErrors();
    }
  };

  const getAccordionCount = (fieldValues) => {
    const answeredCount = fieldValues
      ? Object.keys(fieldValues).filter((key) => {
          const value = fieldValues[key];
          return (
            value &&
            ((typeof value === "string" && isRichTextNotEmpty(value)) ||
              (typeof value !== "string" && value.length > 0))
          );
        }).length
      : 0;
    return answeredCount;
  };

  return (
    <FlexColContainer>
      <Accordion
        title={"Instagram"}
        open={accordionStates.instagram}
        text={`(${getAccordionCount(instagramValues)} options selected)`}
        toggleAccordion={() => toggleAccordion("instagram")}
      >
        <FlexColContainer>
          <div>
            <SubheadingS mt="16px" mb="16px">
              Instagram mandatory:
            </SubheadingS>
            <Controller
              name="channels.instagram"
              control={control}
              render={({ field }) => (
                <CustomCheckbox
                  id={`${field.name}.instagram_mandatory`}
                  text="Instagram is mandatory in this campaign"
                  checked={field.value?.instagram_mandatory?.includes(
                    "Instagram is mandatory in this campaign"
                  )}
                  onChange={(checked) =>
                    handleSingleCheckboxChange(
                      field.name,
                      checked,
                      "instagram_mandatory",
                      "Instagram is mandatory in this campaign"
                    )
                  }
                />
              )}
            />
          </div>
          <div>
            <SubheadingS mb="16px">I would like the influencer to:</SubheadingS>
            <Controller
              name="channels.instagram"
              control={control}
              render={({ field }) => (
                <CustomCheckbox
                  id={`${field.name}.instagram_do_what.post`}
                  text="Post a post on Instagram"
                  checked={field.value?.instagram_do_what?.includes(
                    "Post a post on Instagram"
                  )}
                  onChange={(checked) =>
                    handleCheckboxChange(
                      field.name,
                      checked,
                      "instagram_do_what",
                      "Post a post on Instagram"
                    )
                  }
                />
              )}
            />
            <Controller
              name="channels.instagram"
              control={control}
              render={({ field }) => (
                <CustomCheckbox
                  id={`${field.name}.instagram_do_what.story`}
                  text="Post a story on Instagram"
                  checked={field.value?.instagram_do_what?.includes(
                    "Post a story on Instagram"
                  )}
                  onChange={(checked) =>
                    handleCheckboxChange(
                      field.name,
                      checked,
                      "instagram_do_what",
                      "Post a story on Instagram"
                    )
                  }
                />
              )}
            />
            <Controller
              name="channels.instagram"
              control={control}
              render={({ field }) => (
                <CustomCheckbox
                  id={`${field.name}.instagram_do_what.reel`}
                  text="Post a reel on Instagram"
                  checked={field.value?.instagram_do_what?.includes(
                    "Post a reel on Instagram"
                  )}
                  onChange={(checked) =>
                    handleCheckboxChange(
                      field.name,
                      checked,
                      "instagram_do_what",
                      "Post a reel on Instagram"
                    )
                  }
                />
              )}
            />
          </div>
          <div>
            <SubheadingS mb="0px">
              And tag these instagram accounts:
            </SubheadingS>
            <ParagraphM mt={"4px"} mb={"16px"} color={"#727272"}>
              Type your input and press Enter to add it as a handle{" "}
            </ParagraphM>
            <Controller
              name="channels.instagram"
              control={control}
              render={({ field }) => (
                <MultiInput
                  id={`${field.name}.instagram_tag_account`}
                  value={field.value?.instagram_tag_account || []}
                  onChange={(values) =>
                    handleMultiInputChange(
                      field.name,
                      "instagram_tag_account",
                      values
                    )
                  }
                  placeholder={"Start typing"}
                />
              )}
            />
          </div>
          <div>
            <SubheadingS mb="0px">And use these hashtags:</SubheadingS>
            <ParagraphM mt={"4px"} mb={"16px"} color={"#727272"}>
              Type your input and press Enter to add it as a hashtag{" "}
            </ParagraphM>
            <Controller
              name="channels.instagram"
              control={control}
              render={({ field }) => (
                <MultiInput
                  id={`${field.name}.instagram_tag_hashtag`}
                  value={field.value?.instagram_tag_hashtag || []}
                  onChange={(values) =>
                    handleMultiInputChange(
                      field.name,
                      "instagram_tag_hashtag",
                      values
                    )
                  }
                  placeholder={"Start typing"}
                />
              )}
            />
          </div>
          {/** Rich Text Editor **/}
          <div style={{ marginBottom: "16px" }}>
            <SubheadingS>Instructions</SubheadingS>
            <ParagraphM mt={"4px"} mb={"8px"} color={"#727272"}>
              What do you want creatives to do with this channel?
            </ParagraphM>
            <Controller
              name="channels.instagram.instagram_instructions"
              control={control}
              render={({ field }) => (
                <RichTextComponent
                  editorContent={field.value}
                  setEditorContent={handleRichTextChange(field)}
                  placeholder="Enter description"
                />
              )}
            />
          </div>
        </FlexColContainer>
      </Accordion>
      <Accordion
        title="Facebook"
        text={`(${getAccordionCount(facebookValues)} options selected)`}
        open={accordionStates.facebook}
        toggleAccordion={() => toggleAccordion("facebook")}
      >
        <FlexColContainer>
          <div>
            <SubheadingS mt="16px" mb="16px">
              Instagram mandatory:
            </SubheadingS>
            <Controller
              name="channels.facebook"
              control={control}
              render={({ field }) => (
                <CustomCheckbox
                  id={`${field.name}.facebook_mandatory`}
                  text="Facebook is mandatory in this campaign"
                  checked={field.value?.facebook_mandatory?.includes(
                    "Facebook is mandatory in this campaign"
                  )}
                  onChange={(checked) =>
                    handleSingleCheckboxChange(
                      field.name,
                      checked,
                      "facebook_mandatory",
                      "Facebook is mandatory in this campaign"
                    )
                  }
                />
              )}
            />
          </div>
          <div>
            <SubheadingS mb="16px">I would like the influencer to:</SubheadingS>
            <Controller
              name="channels.facebook"
              control={control}
              render={({ field }) => (
                <CustomCheckbox
                  id={`${field.name}.facebook_do_what.post`}
                  text="Post a post on Facebook"
                  checked={field.value?.facebook_do_what?.includes(
                    "Post a post on Facebook"
                  )}
                  onChange={(checked) =>
                    handleCheckboxChange(
                      field.name,
                      checked,
                      "facebook_do_what",
                      "Post a post on Facebook"
                    )
                  }
                />
              )}
            />
            <Controller
              name="channels.facebook"
              control={control}
              render={({ field }) => (
                <CustomCheckbox
                  id={`${field.name}.facebook_do_what.story`}
                  text="Post a story on Facebook"
                  checked={field.value?.facebook_do_what?.includes(
                    "Post a story on Facebook"
                  )}
                  onChange={(checked) =>
                    handleCheckboxChange(
                      field.name,
                      checked,
                      "facebook_do_what",
                      "Post a story on Facebook"
                    )
                  }
                />
              )}
            />
          </div>
          <div>
            <SubheadingS mb="0px">And tag these Facebook accounts:</SubheadingS>
            <ParagraphM mt={"4px"} mb={"16px"} color={"#727272"}>
              Type your input and press Enter to add it as a handle{" "}
            </ParagraphM>
            <Controller
              name="channels.facebook"
              control={control}
              render={({ field }) => (
                <MultiInput
                  id={`${field.name}.facebook_tag_account`}
                  value={field.value?.facebook_tag_account || []}
                  onChange={(values) =>
                    handleMultiInputChange(
                      field.name,
                      "facebook_tag_account",
                      values
                    )
                  }
                  placeholder={"Start typing"}
                />
              )}
            />
          </div>
          <div>
            <SubheadingS mb="0px">And use these hashtags:</SubheadingS>
            <ParagraphM mt={"4px"} mb={"16px"} color={"#727272"}>
              Type your input and press Enter to add it as a hashtag{" "}
            </ParagraphM>
            <Controller
              name="channels.facebook"
              control={control}
              render={({ field }) => (
                <MultiInput
                  id={`${field.name}.facebook_tag_hashtag`}
                  value={field.value?.facebook_tag_hashtag || []}
                  onChange={(values) =>
                    handleMultiInputChange(
                      field.name,
                      "facebook_tag_hashtag",
                      values
                    )
                  }
                  placeholder={"Start typing"}
                />
              )}
            />
          </div>
          {/** Rich Text Editor **/}
          <div style={{ marginBottom: "16px" }}>
            <SubheadingS>Instructions</SubheadingS>
            <ParagraphM mt={"4px"} mb={"8px"} color={"#727272"}>
              What do you want creatives to do with this channel?
            </ParagraphM>
            <Controller
              name="channels.facebook.facebook_instructions"
              control={control}
              render={({ field }) => (
                <RichTextComponent
                  editorContent={field.value}
                  setEditorContent={handleRichTextChange(field)}
                  placeholder="Enter description"
                />
              )}
            />
          </div>
        </FlexColContainer>
      </Accordion>
      <Accordion
        title="Pinterest"
        text={`(${getAccordionCount(pinterestValues)} options selected)`}
        open={accordionStates.pinterest}
        toggleAccordion={() => toggleAccordion("pinterest")}
      >
        <FlexColContainer>
          <div>
            <SubheadingS mt="16px" mb="16px">
              Pinterest mandatory:
            </SubheadingS>
            <Controller
              name="channels.pinterest"
              control={control}
              render={({ field }) => (
                <CustomCheckbox
                  id={`${field.name}.pinterest_mandatory`}
                  text="Pinterest is mandatory in this campaign"
                  checked={field.value?.pinterest_mandatory?.includes(
                    "Pinterest is mandatory in this campaign"
                  )}
                  onChange={(checked) =>
                    handleSingleCheckboxChange(
                      field.name,
                      checked,
                      "pinterest_mandatory",
                      "Pinterest is mandatory in this campaign"
                    )
                  }
                />
              )}
            />
          </div>
          <div>
            <SubheadingS mb="16px">I would like the influencer to:</SubheadingS>
            <Controller
              name="channels.pinterest"
              control={control}
              render={({ field }) => (
                <CustomCheckbox
                  id={`${field.name}.pinterest_do_what.post`}
                  text="Post a post on Pinterest"
                  checked={field.value?.pinterest_do_what?.includes(
                    "Post a post on Pinterest"
                  )}
                  onChange={(checked) =>
                    handleCheckboxChange(
                      field.name,
                      checked,
                      "pinterest_do_what",
                      "Post a post on Pinterest"
                    )
                  }
                />
              )}
            />
            <Controller
              name="channels.pinterest"
              control={control}
              render={({ field }) => (
                <CustomCheckbox
                  id={`${field.name}.pinterest_do_what.story`}
                  text="Post a story on Pinterest"
                  checked={field.value?.pinterest_do_what?.includes(
                    "Post a story on Pinterest"
                  )}
                  onChange={(checked) =>
                    handleCheckboxChange(
                      field.name,
                      checked,
                      "pinterest_do_what",
                      "Post a story on Pinterest"
                    )
                  }
                />
              )}
            />
          </div>
          <div>
            <SubheadingS mb="0px">
              And tag these Pinterest accounts:
            </SubheadingS>
            <ParagraphM mt={"4px"} mb={"16px"} color={"#727272"}>
              Type your input and press Enter to add it as a handle{" "}
            </ParagraphM>
            <Controller
              name="channels.pinterest"
              control={control}
              render={({ field }) => (
                <MultiInput
                  id={`${field.name}.pinterest_tag_account`}
                  value={field.value?.pinterest_tag_account || []}
                  onChange={(values) =>
                    handleMultiInputChange(
                      field.name,
                      "pinterest_tag_account",
                      values
                    )
                  }
                  placeholder={"Start typing"}
                />
              )}
            />
          </div>
          <div>
            <SubheadingS mb="0px">And use these hashtags:</SubheadingS>
            <ParagraphM mt={"4px"} mb={"16px"} color={"#727272"}>
            Type your input and press Enter to add it as a hashtag{" "}
          </ParagraphM>
            <Controller
              name="channels.pinterest"
              control={control}
              render={({ field }) => (
                <MultiInput
                  id={`${field.name}.pinterest_tag_hashtag`}
                  value={field.value?.pinterest_tag_hashtag || []}
                  onChange={(values) =>
                    handleMultiInputChange(
                      field.name,
                      "pinterest_tag_hashtag",
                      values
                    )
                  }
                  placeholder={"Start typing"}
                />
              )}
            />
          </div>
          {/** Rich Text Editor **/}
          <div style={{ marginBottom: "16px" }}>
            <SubheadingS>Instructions</SubheadingS>
            <ParagraphM mt={"4px"} mb={"8px"} color={"#727272"}>
              What do you want creatives to do with this channel?
            </ParagraphM>
            <Controller
              name="channels.pinterest.pinterest_instructions"
              control={control}
              render={({ field }) => (
                <RichTextComponent
                  editorContent={field.value}
                  setEditorContent={handleRichTextChange(field)}
                  placeholder="Enter description"
                />
              )}
            />
          </div>
        </FlexColContainer>
      </Accordion>
      <Accordion
        title="Thursd"
        text={`(${getAccordionCount(thursdValues)} options selected)`}
        open={accordionStates.thursd}
        toggleAccordion={() => toggleAccordion("thursd")}
      >
        <FlexColContainer>
          <div>
            <SubheadingS mt="16px" mb="16px">
              Thursd mandatory:
            </SubheadingS>
            <Controller
              name="channels.thursd"
              control={control}
              render={({ field }) => (
                <CustomCheckbox
                  id={`${field.name}.thursd_mandatory`}
                  text="Thursd is mandatory in this campaign"
                  checked={field.value?.thursd_mandatory?.includes(
                    "Thursd is mandatory in this campaign"
                  )}
                  onChange={(checked) =>
                    handleSingleCheckboxChange(
                      field.name,
                      checked,
                      "thursd_mandatory",
                      "Thursd is mandatory in this campaign"
                    )
                  }
                />
              )}
            />
          </div>
          {/** Rich Text Editor **/}
          <div style={{ marginBottom: "0px" }}>
            <SubheadingS>Requirements</SubheadingS>
            <ParagraphM mt={"4px"} mb={"8px"} color={"#727272"}>
              What are the requirements for the creative?
            </ParagraphM>
            <Controller
              name="channels.thursd.thursd_requirements"
              control={control}
              render={({ field }) => (
                <RichTextComponent
                  editorContent={field.value}
                  setEditorContent={handleRichTextChange(field)}
                  placeholder="Enter the requirements"
                />
              )}
            />
          </div>

          <div>
            <SubheadingS mb="16px">URLS to add:</SubheadingS>
            <Controller
              name="channels.thursd"
              control={control}
              render={({ field }) => (
                <MultiInput
                  id={`${field.name}.thursd_urls`}
                  value={field.value?.thursd_urls || []}
                  onChange={(values) =>
                    handleMultiInputChange(field.name, "thursd_urls", values)
                  }
                  placeholder={"Start typing"}
                />
              )}
            />
          </div>
          <div style={{ marginBottom: "16px" }}>
            <SubheadingS mb="16px">Any other notes?</SubheadingS>
            <Controller
              name="channels.thursd.thursd_notes"
              control={control}
              render={({ field }) => (
                <RichTextComponent
                  editorContent={field.value}
                  setEditorContent={handleRichTextChange(field)}
                  placeholder="Enter other notes"
                />
              )}
            />
          </div>
        </FlexColContainer>
      </Accordion>
      <Accordion
        title="Other"
        text={`(${getAccordionCount(otherValues)} options selected)`}
        open={accordionStates.other}
        toggleAccordion={() => toggleAccordion("other")}
      >
        <FlexColContainer>
          <div>
            <SubheadingS mt="16px" mb="16px">
              Another channel mandatory:
            </SubheadingS>
            <Controller
              name="channels.other"
              control={control}
              render={({ field }) => (
                <CustomCheckbox
                  id={`${field.name}.other_mandatory`}
                  text="Another channel is mandatory in this campaign"
                  checked={field.value?.other_mandatory?.includes(
                    "Another channel is mandatory in this campaign"
                  )}
                  onChange={(checked) =>
                    handleSingleCheckboxChange(
                      field.name,
                      checked,
                      "other_mandatory",
                      "Another channel is mandatory in this campaign"
                    )
                  }
                />
              )}
            />
          </div>
          {/** Rich Text Editor **/}
          <div style={{ marginBottom: "0px" }}>
            <SubheadingS>Requirements</SubheadingS>
            <ParagraphM mt={"4px"} mb={"8px"} color={"#727272"}>
              What are the requirements for the creative?
            </ParagraphM>
            <Controller
              name="channels.other.other_requirements"
              control={control}
              render={({ field }) => (
                <RichTextComponent
                  editorContent={field.value}
                  setEditorContent={handleRichTextChange(field)}
                  placeholder="Enter the requirements"
                />
              )}
            />
          </div>
          <div>
            <SubheadingS mb="16px">URLS to add:</SubheadingS>
            <Controller
              name="channels.other"
              control={control}
              render={({ field }) => (
                <MultiInput
                  id={`${field.name}.other_urls`}
                  value={field.value?.other_urls || []}
                  onChange={(values) =>
                    handleMultiInputChange(field.name, "other_urls", values)
                  }
                  placeholder={"Start typing"}
                />
              )}
            />
          </div>
          <div style={{ marginBottom: "16px" }}>
            <SubheadingS mb="16px">Any other notes?</SubheadingS>
            <Controller
              name="channels.other.other_notes"
              control={control}
              render={({ field }) => (
                <RichTextComponent
                  editorContent={field.value}
                  setEditorContent={handleRichTextChange(field)}
                  placeholder="Enter other notes"
                />
              )}
            />
          </div>
        </FlexColContainer>
      </Accordion>
    </FlexColContainer>
  );
};

export default ChannelRequirements;
